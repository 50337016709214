import { SideBarNavigation } from "@/common/types/layout.types";
import {
  AcademicCapIcon,
  BellIcon,
  BriefcaseIcon,
  CashIcon,
  DocumentRemoveIcon,
  GiftIcon,
  HomeIcon,
  OfficeBuildingIcon,
  UserGroupIcon,
  CreditCardIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { CurrencyDollar } from "styled-icons/bootstrap";

export const AdminLinks: SideBarNavigation[] = [
  { name: "Home", href: "/admin", icon: HomeIcon },
  {
    name: "Users",
    href: "/admin/users",
    icon: UserGroupIcon,
  },
  {
    name: "Organizations",
    href: "/admin/organizations",
    icon: OfficeBuildingIcon,
  },
  {
    name: "Stores",
    href: "/admin/store",
    icon: ShoppingCartIcon,
  },
  {
    name: "Cashier",
    href: "/admin/suborg",
    icon: CashIcon,
  },
  {
    name: "Clients",
    href: "/admin/clients",
    icon: AcademicCapIcon,
  },
  {
    name: "Jackpot",
    href: "/admin/jackpot",
    icon: BriefcaseIcon,
  },
  {
    name: "Gift Cards",
    href: "/admin/giftCard",
    icon: GiftIcon,
  },
  {
    name: "Physical Gift Cards",
    href: "/admin/physical-giftCard",
    icon: GiftIcon,
  },

  {
    name: "Transactions",
    href: "/admin/transaction",
    icon: CurrencyDollar,
  },
  {
    name: "Invoicing",
    href: "/admin/invoice",
    icon: DocumentRemoveIcon,
  },
  {
    name: "Virements",
    href: "/admin/payments",
    icon: CreditCardIcon,
  },
  {
    name: "Chiffre d'affaire",
    href: "/admin/commissions",
    icon: CreditCardIcon,
  },
  {
    name: "Alertes",
    href: "/admin/alertCenter",
    icon: BellIcon,
  },
];
